import React from 'react';
import { Link, Logo, Paragraph, RichText, Wrap } from '@leafwell/components';

import SocialItems from './social-items';
import { useWordPressContext } from 'contexts/wordpress-provider';
import { useEventsTracker } from 'contexts/eventsTracker';

type FooterProps = {
  showNavFooter: boolean;
};

const Footer: React.FC<FooterProps> = ({ showNavFooter = true }) => {
  const { contacts, copyright, footerMenu, siteTitle } = useWordPressContext();
  const copyrightText = React.useMemo(() => {
    const date = new Date();
    return copyright.replace('[year]', date.getFullYear().toString());
  }, [copyright]);
  const { trackEvent } = useEventsTracker();

  return (
    <Wrap
      size="large"
      tagName="footer"
      className="md:grid-cols-3 xl:grid-cols-12 gap-x-16 xl:gap-x-3"
    >
      {showNavFooter ? (
        <>
          <Logo variant="icon" title={siteTitle} className="lg:w-20" />
          {footerMenu?.length > 0 && (
            <nav className="grid gap-y-3 gap-x-6 lg:gap-x-10 sm:grid-cols-2 xl:col-span-3 xl:col-start-4 self-start">
              {footerMenu?.map(({ title, url }, key) => (
                <Link
                  key={key}
                  href={url}
                  target="_self"
                  variant="underlineHover"
                  onClick={() =>
                    trackEvent({
                      event: 'select_content',
                      content_type: title,
                      item_id: 'page',
                      origin: 'footer',
                    })
                  }
                >
                  {title}
                </Link>
              ))}
            </nav>
          )}
          <section className="grid gap-y-2 md:gap-y-6 content-start md:text-right justify-items-start md:justify-items-end md:justify-end xl:col-start-10 xl:col-span-3">
            <SocialItems variant="withIcon" />
          </section>
        </>
      ) : null}
      <div className="grid xl:col-span-5 gap-y-4">
        {!showNavFooter ? (
          <>
            <Logo variant="icon" classNameButton="w-8" />
            <div className="flex flex-col xl:flex-row xl:items-end gap-x-8 gap-y-4">
              <RichText
                content={contacts}
                className="text-sm"
                tagName="section"
                suppressHydrationWarning
              />
              <Link href="/privacy-policy" target="_blank" variant="underline">
                Privacy Policy
              </Link>
            </div>
          </>
        ) : (
          <RichText
            content={contacts}
            className="text-sm"
            tagName="section"
            suppressHydrationWarning
          />
        )}
      </div>
      <Paragraph
        size="xsmall"
        className="self-end md:justify-self-end md:col-span-2 xl:col-span-7"
      >
        {copyrightText}
      </Paragraph>
    </Wrap>
  );
};

export default Footer;
